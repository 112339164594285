import {
  platformAdapter,
  PlatformRecommendationsRequest,
  PlatformSemanticQueriesRequest,
  PlatformResult,
  recommendationsRequestSchema,
  resultSchema,
  semanticQueriesRequestSchema
} from '@empathyco/x-adapter-platform';
import { RecommendationsRequest, Result, SemanticQueriesRequest } from '@empathyco/x-types';

export const adapter = platformAdapter;

/* Code sample about how to extend the result mapper with more fields. */

interface EmpathyDemoPlatformResult extends PlatformResult {
  description: string;
  collection: string;
  brand: string;
  discount: string;
  name: string;
  hasStock: number;
  hasAttributes: boolean;
}

declare module '@empathyco/x-types' {
  export interface Result {
    collection: string;
    description: string;
    brand: string;
    discount: number;
    stock: number;
    hasAttributes: boolean;
  }
}

resultSchema.$override<EmpathyDemoPlatformResult, Partial<Result>>({
  description: 'description',
  collection: 'collection',
  brand: 'brand',
  name: 'name',
  stock: 'hasStock',
  hasAttributes: 'hasAttributes',
  images: ({ __images }) => (Array.isArray(__images) ? __images.reverse() : [__images]),
  price: {
    value: ({ __prices }) => __prices?.current?.value,
    originalValue: ({ __prices }) => __prices?.previous?.value ?? __prices?.current.value,
    futureValue: ({ __prices }) => __prices?.future?.value ?? __prices?.current.value,
    hasDiscount: ({ __prices }) =>
      __prices?.current.value < (__prices?.previous?.value ?? __prices?.current.value)
  },
  discount: ({ __prices }) => {
    if (__prices) {
      return __prices.previous?.value
        ? Math.round(
            -((__prices.previous?.value - __prices.current.value) * 100) / __prices.previous?.value
          )
        : 0;
    }
    return 0;
  }
});

recommendationsRequestSchema.$override<
  RecommendationsRequest,
  Partial<PlatformRecommendationsRequest>
>({
  // TODO Top clicked demo endpoint breaks if it receives the scope parameter
  extraParams: ({ extraParams: { scope, ...extraParams } = {} }) => extraParams
});

semanticQueriesRequestSchema.$override<
  SemanticQueriesRequest,
  Partial<PlatformSemanticQueriesRequest>
>({
  extraParams: ({ extraParams }) => {
    return {
      extraParams,
      filter_ids: 'NOT_ALL_WORDS,NOT_PARTIAL'
    };
  }
});
